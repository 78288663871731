<template>
  <div
    v-show="showPage"
    class="Start">
    <SimpleHeader />

    <div class="ContentWrap">
      <span class="Title">{{ mixWB('BRIEFLY_ABOUT_YOU') }}</span>
      <span>{{ mixWB('FILL_IN_THESE_TO_GET_STARTED') }}</span>
      <div class="FormWrap">
        <UserInfoForm />
      </div>
    </div>

  </div>
</template>

<script>
import UserInfoForm from '@/components/Forms/UserInfoForm'
import SimpleHeader from '@/components/SimpleHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  data() {
    return {
      showPage: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'currentUserAnswers',
      'questionSchemas',
    ]),
  },
  components: {
    UserInfoForm,
    SimpleHeader,
  },
  created() {
    if (!this.currentUserData.firstName || !this.currentUserData.lastName) {
      this.showPage = true
      return
    }

    this.$router.push({ name: 'Welcome' })
  },
}
</script>

<style lang="stylus" scoped>
  .Start
    display block
    padding-bottom 100px

  .ContentWrap
    width 570px
    margin 0 auto
    padding 30px 30px 70px
    background-color rgba($color_grey_lightest, 0.8)
    span
      text-align center
      padding-bottom 15px
    .Title
      font-size 1.25rem
      font-weight bold
      padding-bottom 20px

  .FormWrap
    margin 0 auto 40px
    width 380px
</style>
