<template>
  <form
    class="UserInfoForm"
    @submit="onFormSubmit">
    <InputField
      backgroundColor="white"
      name="firstName"
      :value="firstName"
      :placeholder="mixWB('FIRST_NAME')"
      @on-update="onFieldUpdate" />
    <InputField
      backgroundColor="white"
      name="lastName"
      :value="lastName"
      :placeholder="mixWB('LAST_NAME')"
      @on-update="onFieldUpdate" />
    <InputField
      backgroundColor="white"
      name="workTitle"
      :value="workTitle"
      :placeholder="mixWB('TITLE_POSITION_OPTIONAL')"
      @on-update="onFieldUpdate" />

    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('CONTINUE')"
        :isLoading="isLoading"
        :fullWidth="false"
        @button-click="onFormSubmit" />
    </div>
  </form>
</template>

<script>
import Button from '@/components/Buttons/Button'
import { mapGetters } from 'vuex'
import InputField from '../FormElements/InputField'

export default {
  name: 'UserInfoForm',
  data() {
    return {
      firstName: '',
      lastName: '',
      workTitle: '',
      errorText: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserData',
    ]),
  },
  methods: {
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value.trim()
    },
    onFormSubmit(e) {
      if (e) {
        e.preventDefault()
      }

      // Validate form
      if (!this.firstName) {
        this.errorText = this.mixWB('MISSING_FIRST_NAME')
        return
      }
      if (!this.lastName) {
        this.errorText = this.mixWB('MISSING_LAST_NAME')
        return
      }

      this.isLoading = true

      // Save user info
      this.$store.dispatch('setUserInfo', {
        firstName: this.firstName,
        lastName: this.lastName,
        workTitle: this.workTitle,
      })

      setTimeout(() => {
        this.$router.push({ name: 'Welcome' })
      }, 250)
    },
  },
  components: {
    InputField,
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .UserInfoForm
    display block

  .ErrorText
    text-align center
    display block
    margin-bottom 15px
    color $color_error

  .ButtonWrap
    display flex
    justify-content center
    > div
      min-width 200px
</style>
